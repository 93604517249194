import {createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';

//Pages:
import Navbar from './pages/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Art from './pages/Art';
import Resume from './pages/Resume';
import Music from './pages/Music';
import Commission from './pages/Commission';
import Gallery from './pages/Gallery';
import NotFound from './pages/NotFound';

const mainRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Navbar/>}>
      <Route index element={<Home/>}/>
      <Route path="about" element={<About/>}/>
      <Route path="art" element={<Art/>}/>
      <Route path="art/astronamials/:id" element={<Gallery cate="astronamials"/>}/>
      <Route path="art/vgfanart/:id" element={<Gallery cate="vgfanart"/>}/>
      <Route path="art/emotes/:id" element={<Gallery cate="emotes"/>}/>
      <Route path="art/artfight/:id" element={<Gallery cate="artfight"/>}/>
      <Route path="art/otherfanart/:id" element={<Gallery cate="otherfanart"/>}/>
      <Route path="music/:id" element={<Music />}/>
      <Route path="resume" element={<Resume/>}/>
      <Route path="commission" element={<Commission/>}/>
      <Route path="404" element={<NotFound/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Route>
  )
)

function App() {
  //Javascript can be written here.
  return (<RouterProvider router={mainRouter}/>);
}

export default App;
