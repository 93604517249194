import banner from "../img/nyanbanner.png";

const Home = () => {
    return (
        <div className="body">
            <div className="home">
                <img src={banner} alt="MrNyan Home Banner"/>
                <h1>Welcome to Mr. Nyan's Universe!</h1>
                <div className="header">
                    <p>Use the menu above to explore the universe filled with MrNyan's art, music covers, and more!</p>
                </div>
            </div>
        </div>
    );
}
 
export default Home;