import { NavLink, useParams } from "react-router-dom";
import data from "../file/db.json";
import { useState } from "react";
import prevImage from "../img/prev.png";
import nextImage from "../img/next.png";

const Music = () => {
    //Fetches Id number as page from url:
    const { id } = useParams(); 
    const page = parseInt(id);
    const perPage = 4;
    //Calculates the amount of pages in total of the filtered array.
    //Note: Each page holds four thumbnails.
    const maxPage = Math.floor(data.musics.length/perPage-0.0001);
    //Sets default video onto the Stage.
    const [title, setTitle] = useState(data.musics[0].title);
    const [video, setVideo] = useState(data.musics[0].link);
    const [game, setGame] = useState(data.musics[0].game);
    const [orig, setOrig] = useState(data.musics[0].origArt);
    const [downLink, setDownLink] = useState(data.musics[0].download);
    return (
        //Need to create a database that can hold a table with these categories:
        //1. id
        //2. Title
        //3. Thumbnail Link
        //4. Video Link
        //5. Original Game/Medium
        //6. Original Artist(s)
        //7. Download Link
        //The iframe at the top will hold an embedded link to the the current selected video.
        //The user can click the thumbnail of a video below the iframe to change which video is being played.
        <div className="body">
            <div className="nbs">
                <img className="NBSLogo" src="https://user-images.githubusercontent.com/85085992/194212209-ff794220-25a8-4b4f-8f61-2686b94379e4.png" alt="Logo of Noteblock Studio Series from MrNyan Youtube Channel"/>
            </div>
            <div className="stageVContainer">
                <div className="stageV">
                    <div className="video">
                        {<iframe width="560" height="315" src={video} title={title} frameBorder="0" allow="clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen></iframe>}
                    </div>
                    <div className="videoInfo">
                        <h2 className="title">{title}</h2>
                        <p className="game">{game}</p>
                        <p className="origartist">{orig}</p>
                        <div className="nbsDownload">
                            <div className="videoNote">
                                <a href={downLink} target="_blank" rel="noreferrer"><strong className="download">Download NBS File</strong></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="pageContainer">
                <div className="pageButton">
                    <NavLink to={(page > 0)  && "/music/"+(page-1)} className="PrevPage">
                            <img hidden={page <= 0} src={prevImage} alt="Previous Page Button" className="prev" />
                    </NavLink>
                    <div className="pageNum">
                        <p className="pageNumTitle">Page:</p>
                        <p className="pageNumValue">{page+1}/{maxPage+1}</p>
                    </div>
                    <NavLink to={(page < maxPage)  && "/music/"+(page+1)} className="NextPage">
                            <img hidden={page >= maxPage} src={nextImage} alt="Next Page Button" className="next" />
                    </NavLink>
                </div>
            </div>
            <div className="Vlist">
                {[...Array(perPage).keys()].map((i) => {
                    if (data.musics[(page*perPage)+i])
                    {return(<div className="Ventry" key={data.musics[(page*perPage)+i].id}>
                        <NavLink onClick={() => {
                            setTitle(data.musics[(page*perPage)+i].title);
                            setVideo(data.musics[(page*perPage)+i].link);
                            setGame(data.musics[(page*perPage)+i].game);
                            setOrig(data.musics[(page*perPage)+i].origArt);
                            setDownLink(data.musics[(page*perPage)+i].download);
                            window && window.scroll(0,0);}}>
                            <img src={data.musics[(page*perPage)+i].thumb} alt={data.musics[(page*perPage)+i].title} className="thumb"/>
                        </NavLink>
                    </div>)}
                    else{
                        return(false)
                    }
                })}
            </div>
            <div className="pageContainer">
                <div className="pageButton">
                    <NavLink to={(page > 0)  && "/music/"+(page-1)} className="PrevPage">
                            <img hidden={page <= 0} src={prevImage} alt="Previous Page Button" className="prev" />
                    </NavLink>
                    <div className="pageNum">
                        <p className="pageNumTitle">Page:</p>
                        <p className="pageNumValue">{page+1}/{maxPage+1}</p>
                    </div>
                    <NavLink to={(page < maxPage)  && "/music/"+(page+1)} className="NextPage">
                            <img hidden={page >= maxPage} src={nextImage} alt="Next Page Button" className="next" />
                    </NavLink>
                </div>
            </div>
        </div> 
     );
}
 
export default Music;
