import pdf from "../file/JWCResume.pdf";

const Resume = () => {
    return (
        <div className="body">
            <div className="resume">
                <iframe src={pdf} title="John's Resume"></iframe>
                <div className="resumeExtern">
                    <div className="resumeButton">
                        <a href={pdf} target="_blank" rel="noreferrer">
                            <p className="resumeLink">Click here if it did not load!</p>
                        </a>
                    </div>
                </div>

            </div>

        </div> 
     );
}
 
export default Resume;
