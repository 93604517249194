import { NavLink } from "react-router-dom";
import selfPort from "../img/selfPort.png";

const About = () => {
    return (
        <div className="body">
            <div className="about">
                <div className="meSection">
                    <div className="john">
                        <div className="profilePic0">
                        <img src={selfPort} alt="John W Caldwell's Self Portrait"/>
                        </div>
                        <div className="profileInfo0">
                            <h2> John W. Caldwell</h2>
                            <p><span>Birthday: </span>June 9</p>
                            <p><span>Pronouns: </span>He/Him</p>
                        </div> 
                        <div className="bio0">
                            <p>Hello! I am John Caldwell, a graduate of North Carolina State University and Fayetteville State University in B.S. Computer Engineering and B.S. Computer Science, respectively. I enjoy front-end and software development in various coding languages, including C++, Python, and Javascript. I also have some experience in back-end development through SQL. I am always willing to learn more in the software engineering field! As a few of my hobbies, I love to draw digitally, tinker with smaller coding projects and mods, make music covers, and play Pokemon Go!</p>
                        </div>
                    </div>
                </div>
                <hr/>
                <span><h1>Main Characters:</h1></span>
                <div className="charactersList">
                    <div className="nyan">
                        <div className="profilePic1">
                            <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9357a819-92a7-46e6-90be-4765cafc6160/df7vb4k-6f5ffe20-88a4-47bb-9b46-fdc6e0aeaada.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzkzNTdhODE5LTkyYTctNDZlNi05MGJlLTQ3NjVjYWZjNjE2MFwvZGY3dmI0ay02ZjVmZmUyMC04OGE0LTQ3YmItOWI0Ni1mZGM2ZTBhZWFhZGEucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.3qNuqQVrMxUk-LG581UZPfs7QzVB7Sp9ra8bIZddBmk" alt="Avatar of Mr. Nyan from Astronamials"/>
                        </div>
                        <div className="profileInfo1">
                            <h2> Mr. Mewlver Nyan</h2>
                            <p><span>Species: </span>Sand Cat</p>
                            <p><span>Gender: </span>Male</p>
                            <p><span>Occupations: </span>Platoon leader & Engineer</p>
                        </div>
                        <div className="bio1">
                            <p>Mr. Nyan is the platoon leader and sub-engineer of Class-D182. He is a male feline and the youngest son to a famous father who is an expedition survivor from outside the outer space belt of their planetary belt system. Mewlver prefers the tech field, but was ultimately pushed into his role of a platoon leader by his father after his unsuspected return from his expedition. He tends to be lazy and a prankster when nothing is happening, but takes his leadership role seriously when necessary.  Now, Mr. Nyan begrudgingly travels around the belt with his team to deliver packages!</p>
                        </div>
                    </div>
                    <div className="woofer">
                        <div className="profilePic2">
                            <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9357a819-92a7-46e6-90be-4765cafc6160/df7vb10-b388ee59-78bd-484b-a59e-e0a7680c526c.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzkzNTdhODE5LTkyYTctNDZlNi05MGJlLTQ3NjVjYWZjNjE2MFwvZGY3dmIxMC1iMzg4ZWU1OS03OGJkLTQ4NGItYTU5ZS1lMGE3NjgwYzUyNmMucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.mTCyXRKEPDHf_8ufJfRyaAfV-A6G9QWkd-d8_EIIPWA" alt="Avatar of Ms. Woofer from Astronamials"/>
                        </div>
                        <div className="profileInfo2">
                            <h2> Ms. Brauni Woofer</h2>
                            <p><span>Species: </span>Poodle</p>
                            <p><span>Gender: </span>Female</p>
                            <p><span>Occupations: </span>Soldier & Hauler</p>
                        </div>
                        <div className="bio2">
                            <p>Ms. Woofer is the team's main soldier and sub-hauler of Class-D182. She is a female canine who left her family business to see new worlds! Because of her abnormal arm strength, Brauni cannot use normal weapons well, but can handle turrets on ships! Thanks to her strength, she was able to land a soldier role onto a platoon against her family's request. She usually is very optimistic and headstrong in most situations but very sensitive and emotional in others. Now, Ms. Woofer awaits the adventures she will find in her travels around the belt!  </p>
                        </div>
                    </div>
                    <div className="hops">
                        <div className="profilePic3">
                            <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9357a819-92a7-46e6-90be-4765cafc6160/df7vayb-cb6d82bb-bb9b-4ef2-8131-60aee0e3af81.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzkzNTdhODE5LTkyYTctNDZlNi05MGJlLTQ3NjVjYWZjNjE2MFwvZGY3dmF5Yi1jYjZkODJiYi1iYjliLTRlZjItODEzMS02MGFlZTBlM2FmODEucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.t-H4tprLwFN5OMfR-LXgyL1w8CO418Zc2BxFrpjoGyo" alt="Avatar of Mr. Hops from Astronamials"/>
                        </div>
                        <div className="profileInfo3">
                            <h2> Mr. ??? Hops</h2>
                            <p><span>Species: </span>Hare</p>
                            <p><span>Gender: </span>Male</p>
                            <p><span>Occupations: </span>Navigator & Analyst</p>
                        </div>
                        <div className="bio3">
                            <p>Mr. Hops is the team's main navigator and sub-analyst of Class-D182. He is a male lepus who lived in a huge family known for its large agricultural yield for the entire planetary belt system. Unlike the rest of the family, he had an abnormal growth spurt in his height which made him great as an intimidator for his mother's company. Because of the reputation he gained from this, he chose to sneak away onto a platoon ship and learn to become a leader of a platoon, hoping one day to become just like his hero! Instead, he was only able to get the roles above in a platoon led by his hero's son. He tends to constantly be nervous and a murmurer, but is very knowledgeable on the rules and procedures of the space organization. </p>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="platoon">
                    <NavLink to="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9357a819-92a7-46e6-90be-4765cafc6160/df7varl-01864bf4-4048-4536-9fe4-a42d9c07cd8b.png/v1/fill/w_800,h_527,q_80,strp/nyan_s_platoon__class_d182_by_mrnyanuniverse_df7varl-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NTI3IiwicGF0aCI6IlwvZlwvOTM1N2E4MTktOTJhNy00NmU2LTkwYmUtNDc2NWNhZmM2MTYwXC9kZjd2YXJsLTAxODY0YmY0LTQwNDgtNDUzNi05ZmU0LWE0MmQ5YzA3Y2Q4Yi5wbmciLCJ3aWR0aCI6Ijw9ODAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.tiFqYootsDROwtHlHZ0Gd2ehfeC8HYHclfkZpclB1JQ" >
                        <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9357a819-92a7-46e6-90be-4765cafc6160/df7varl-01864bf4-4048-4536-9fe4-a42d9c07cd8b.png/v1/fill/w_800,h_527,q_80,strp/nyan_s_platoon__class_d182_by_mrnyanuniverse_df7varl-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NTI3IiwicGF0aCI6IlwvZlwvOTM1N2E4MTktOTJhNy00NmU2LTkwYmUtNDc2NWNhZmM2MTYwXC9kZjd2YXJsLTAxODY0YmY0LTQwNDgtNDUzNi05ZmU0LWE0MmQ5YzA3Y2Q4Yi5wbmciLCJ3aWR0aCI6Ijw9ODAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.tiFqYootsDROwtHlHZ0Gd2ehfeC8HYHclfkZpclB1JQ" alt="Mr. Nyan's Platoon from Astronamials"/>
                    </NavLink>
                </div>
            </div>
        </div>       
    );
}
 
export default About;