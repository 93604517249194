const Commission = () => {
    return (
        <div className="body">
            <div className="commission">
                <h2>COMMISSION INFO</h2>
            </div>
        </div> 
     );
}
 
export default Commission;
