import { NavLink } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="body">
            <div className="notfound">
                <h1>You went too far into space...</h1>
                <p>Error 404: Page Not Found!</p>
                <NavLink to="/">Return to Home?</NavLink>
            </div>
        </div> 

     );
}
 
export default NotFound;