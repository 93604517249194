import {NavLink, Outlet} from "react-router-dom"; 
import Footer from './Footer';
import menu from "../img/menu.png";
import close from "../img/close.png";

const closeMenu = () => {document.getElementById("close_button").click()};

const Navbar = () => {
    return ( 
        <div className="App">
            <nav className="navbar">
                <h1 className="Logo">Mr. Nyan's Universe </h1>
                <input type="checkbox" id="navbar_toggle" className="navbar_toggle"/>
                <label id="close_button" htmlFor="navbar_toggle" className="navbar_toggle_label">
                    <img src={menu} className="menu_button" alt="Hamburger Menu Button"/>
                    <img src={close} className="close_button" alt="Close Menu Button"/>
                </label>
                <div className="Pages">
                    <ul>
                        <li><NavLink onClick={closeMenu} to="/" >Home</NavLink></li>
                        <li><NavLink onClick={closeMenu} to="/about" >About</NavLink></li>
                        <li><NavLink onClick={closeMenu} to="/art" >Art Portfolio</NavLink></li>
                        <li><NavLink onClick={closeMenu} to="/music/0" >Music</NavLink></li>
                        <li><a onClick={closeMenu} href="https://ko-fi.com/mrnyanu/commissions" target="_blank" rel="noreferrer" >Commission Me!</a></li>
                        <li><NavLink onClick={closeMenu} to="/resume" >Resume</NavLink></li>
                    </ul>

                </div>
            </nav>
            <div className="content">
                <Outlet/>
            </div>
            <Footer/>
        </div>
     );
}
 
export default Navbar;

/* Code Snippet below by Chris Coyier to stop transition jankiness when changing window size.*/
let resizeTimer;
window.addEventListener("resize", () => {
  document.body.classList.add("resize-animation-stopper");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.body.classList.remove("resize-animation-stopper");
  }, 400);
});