import { NavLink, useParams } from "react-router-dom";
import data from "../file/db.json";
import { useState } from "react";
import prevImage from "../img/prev.png";
import nextImage from "../img/next.png";

const Gallery = ({cate}) => {
    //Fetches Id number as page from url:
    const {id} = useParams(); 
    const page = parseInt(id);
    const perPage = 4;
    //Filters to the chosen art category by the json. 
    const arts = data.arts.filter((art) => art.category === cate);
    //Calculates the amount of pages in total of the filtered array.
    //Note: Each page holds {perPage} images.
    const maxPage = Math.floor(arts.length/perPage-0.0001);
    //Sets default image onto the stage.
    const [pic, setPic] = useState(arts[0].link);
    const [year, setYear] = useState(arts[0].year);
    const [title, setTitle] = useState(arts[0].title);
    const [description, setDesc] = useState(arts[0].description);
    return ( 
        //Categories of the Json file:
        // Arts:
        //1. id
        //2. Title
        //3. Image Link Address
        //4. Category (i.e. VGfanart? astronamials? etc)
        //5. Year
        //6. Description
        // The Art Categories will all lead to this module but will filter to the respective category requested.
        /*---------------------------------------------------*/
        //Gallery.js is made of three components:
        //1. Stage = Location of the current selected image. Displays its title, year drawn, and small description.
        //2. Page Buttons = Buttons that can navigate through the pages of the filtered array of images.
        //3. Gallery = Location of the current page's images previewed. The user can click any of them to replace the image on the Stage. Only up to four images are shown at once.
        <div className="body">
            {cate === "artfight" &&
                <div className="artfight">
                    <a href="https://artfight.net/~MrNyan" target="_blank" rel="noreferrer" className="artfightLink">
                        <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/5d867561-99af-4778-a3d2-81302ef18579/de05pvq-ecc7f5f5-9f5e-4ecf-bcfb-a6aaaf514815.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzVkODY3NTYxLTk5YWYtNDc3OC1hM2QyLTgxMzAyZWYxODU3OVwvZGUwNXB2cS1lY2M3ZjVmNS05ZjVlLTRlY2YtYmNmYi1hNmFhYWY1MTQ4MTUucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.mPEqWSATd_NpkQKRs6Q0aRnpTIkbNRFQnaS5GBGAcfs" alt="MrNyan profile page of ArtFight Website"/>
                    </a>
                </div> 
            }
            <div className="stageContainer">
                <div className="stage">
                    <div className="image">
                        {arts && <a href={pic}><img src={pic} alt={title} className="art" /></a>}
                    </div>
                    <div className="imageInfo">
                        <h2 className="title">{title}</h2>
                        <p className="year">{year}</p>
                        <p className="description">{description}</p>
                        <p className="imageNote">(Click Image to Expand)</p>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="pageContainer">
                <div className="pageButton">
                    <NavLink to={(page > 0)  && "/art/"+(cate)+"/"+(page-1)} className="PrevPage">
                            <img hidden={page <= 0} src={prevImage} alt="Previous Page Button" className="prev" />
                    </NavLink>
                    <div className="pageNum">
                        <p className="pageNumTitle">Page:</p>
                        <p className="pageNumValue">{page+1}/{maxPage+1}</p>
                    </div>
                    <NavLink to={(page < maxPage)  && "/art/"+(cate)+"/"+(page+1)} className="NextPage">
                            <img hidden={page >= maxPage} src={nextImage} alt="Next Page Button" className="next" />
                    </NavLink>
                </div>
            </div>
            <div className="gallery">
                {[...Array(perPage).keys()].map((i) => {
                    if (arts[(page*perPage)+i])
                    {return(<div className="entry" key={arts[(page*perPage)+i].id}>
                        <NavLink onClick={() => {
                            setPic(arts[(page*perPage)+i].link);
                            setYear(arts[(page*perPage)+i].year);
                            setTitle(arts[(page*perPage)+i].title);
                            setDesc(arts[(page*perPage)+i].description);
                            window && window.scroll(0,0);}}>
                            <img src={arts[(page*perPage)+i].link} alt={arts[(page*perPage)+i].title} className="preview"/>
                        </NavLink>
                    </div>)}
                    else{
                        return(false)
                    }
                })}
            </div>
            <div className="pageContainer">
                <div className="pageButton">
                    <NavLink to={(page > 0)  && "/art/"+(cate)+"/"+(page-1)} className="PrevPage">
                            <img hidden={page <= 0} src={prevImage} alt="Previous Page Button" className="prev" />
                    </NavLink>
                    <div className="pageNum">
                        <p className="pageNumTitle">Page:</p>
                        <p className="pageNumValue">{page+1}/{maxPage+1}</p>
                    </div>
                    <NavLink to={(page < maxPage)  && "/art/"+(cate)+"/"+(page+1)} className="NextPage">
                            <img hidden={page >= maxPage} src={nextImage} alt="Next Page Button" className="next" />
                    </NavLink>
                </div>
            </div>
        </div>    
     );
}
 
export default Gallery;