import logo0 from "../img/youtube.png";
import logo1 from "../img/twitter.png";
import logo2 from "../img/twitch.png";
import logo3 from "../img/deviant.png";
import logo4 from "../img/kofi.png";
import logo5 from "../img/github.png";

const Footer = () => {
    const link = ["https://www.youtube.com/@MrNyanUniverse/featured","https://twitter.com/MrNyanUniverse","https://www.twitch.tv/mrnyanuniverse","https://www.deviantart.com/mrnyanuniverse","https://ko-fi.com/mrnyanu","https://github.com/MrNyanUniv"];
    return (
        <nav className="footer">
            <p>© John W. Caldwell. All Rights Reserved.</p>
            <div className="links">
                <ul>
                <li><a href={link[0]} alt="youtube" target="_blank" rel="noreferrer">
                    <img src={logo0} alt="MrNyanUniverse Youtube Channel"/>
                </a></li>
                <li><a href={link[1]}  alt="twitter" target="_blank" rel="noreferrer">
                    <img src={logo1} alt="MrNyanUniverse Twitter Page"/>
                </a></li>
                <li><a href={link[2]}  alt="twitch" target="_blank" rel="noreferrer">
                    <img src={logo2} alt="MrNyanUniverse Twitch Channel"/>
                </a></li>
                <li><a href={link[3]} alt="deviantart" target="_blank" rel="noreferrer">
                    <img src={logo3} alt="MrNyanUniverse DeviantArt Page"></img>
                </a></li>
                <li><a href={link[4]} alt="kofi" target="_blank" rel="noreferrer">
                    <img src={logo4} alt="MrNyanUniverse Kofi Page"/>
                </a></li>
                <li><a href={link[5]} alt="github" target="_blank" rel="noreferrer">
                    <img src={logo5} alt="MrNyanUniverse Github Repository"/>
                </a></li>
                </ul>
            </div> 
        </nav>
    );
}
 
export default Footer