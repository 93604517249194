import {Link} from "react-router-dom"; 

const Art = () => {
    return (
        <div className="body">
            <div className="art">
                <ul className="categories">
                    <li><Link to="astronamials/0" >
                        <div className="artGroup">
                            <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9357a819-92a7-46e6-90be-4765cafc6160/df5xyr0-cbaffaf6-60d6-4eb8-be4d-764513f399e1.png/v1/fill/w_894,h_894,q_70,strp/mrnyan__space_engineer_extraordinaire__by_mrnyanuniverse_df5xyr0-pre.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9OTAwIiwicGF0aCI6IlwvZlwvOTM1N2E4MTktOTJhNy00NmU2LTkwYmUtNDc2NWNhZmM2MTYwXC9kZjV4eXIwLWNiYWZmYWY2LTYwZDYtNGViOC1iZTRkLTc2NDUxM2YzOTllMS5wbmciLCJ3aWR0aCI6Ijw9OTAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.S-ey3B1CODysyCduukx4Zbm5XwdFSG94wM1-sfJI5ds" alt="Category group art of the Astronamial art made by MrNyan."/><p>Astronamials Art</p>
                        </div>
                    </Link></li>
                    <li><Link to="vgfanart/0" >
                        <div className="artGroup">
                            <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9357a819-92a7-46e6-90be-4765cafc6160/dfqa9yg-21b59525-e5dd-4d4f-aa33-529a22e39575.png/v1/fill/w_800,h_753,q_80,strp/favorite_mons_of_each_region_by_mrnyanuniverse_dfqa9yg-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzUzIiwicGF0aCI6IlwvZlwvOTM1N2E4MTktOTJhNy00NmU2LTkwYmUtNDc2NWNhZmM2MTYwXC9kZnFhOXlnLTIxYjU5NTI1LWU1ZGQtNGQ0Zi1hYTMzLTUyOWEyMmUzOTU3NS5wbmciLCJ3aWR0aCI6Ijw9ODAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.lxlMxJE6JHaCb_c88buQ7qJeUInnn7jINRFzmov0U_g" alt="Category group art of the Video Game Fanart art made by MrNyan."/><p>Video Game Fanart</p>
                        </div>
                    </Link></li>
                    <li><Link to="emotes/0" >
                        <div className="artGroup">
                            <img src="https://storage.ko-fi.com/cdn/useruploads/post/f0c5ef31-d7c0-4d77-a2e4-6e0da66c8b46_jfrost.png" alt="Category group art of the Emote art made by MrNyan."/><p>Emotes</p>
                        </div>
                    </Link></li>
                    <li><Link to="artfight/0" >
                        <div className="artGroup">
                            <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/5d867561-99af-4778-a3d2-81302ef18579/de05pvq-ecc7f5f5-9f5e-4ecf-bcfb-a6aaaf514815.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzVkODY3NTYxLTk5YWYtNDc3OC1hM2QyLTgxMzAyZWYxODU3OVwvZGUwNXB2cS1lY2M3ZjVmNS05ZjVlLTRlY2YtYmNmYi1hNmFhYWY1MTQ4MTUucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.mPEqWSATd_NpkQKRs6Q0aRnpTIkbNRFQnaS5GBGAcfs" alt="Category group art of the Artfight art made by MrNyan."/><p>ArtFight</p>
                        </div>
                        </Link></li>
                    <li><Link to="otherfanart/0" >
                        <div className="artGroup">
                            <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9357a819-92a7-46e6-90be-4765cafc6160/df5xyzz-8ac21426-e584-4ee4-859d-6410fa7ecaf0.png/v1/fill/w_800,h_1269,q_80,strp/fionna_the_human_by_mrnyanuniverse_df5xyzz-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTI2OSIsInBhdGgiOiJcL2ZcLzkzNTdhODE5LTkyYTctNDZlNi05MGJlLTQ3NjVjYWZjNjE2MFwvZGY1eHl6ei04YWMyMTQyNi1lNTg0LTRlZTQtODU5ZC02NDEwZmE3ZWNhZjAucG5nIiwid2lkdGgiOiI8PTgwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.-dkIxM5PK6VQJFebSQ4l-ajASB0HTtGPOY5043bPEC8" alt="Category group art of the Other Fanart art made by MrNyan."/><p>Other Fanart</p>
                        </div>
                    </Link></li>
                </ul>
            </div>
        </div> 
     );
}
 
export default Art;
